@keyframes runlineAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes runlineScreenShine {
	0% {
    transform:translateX(-100%);
  }

  47% {
    transform:translateX(-100%);
  }

  50% {
    transform: translateX(-50%);
  }

  55% {
    transform: translateX(10%);
  }

  62% {
    transform: translateX(-100%);
  }

  75% {
    transform:translateX(-100%);
  }

  100% {
    transform:translateX(100%);
  }
}