// # Museo Sans Cyrl
@font-face {
  font-family: "Museo Sans";
  src: url("/fonts/MuseoSansCyrl/MuseoSansCyrl-black.otf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans";
  src: url("/fonts/MuseoSansCyrl/MuseoSansCyrl-bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans";
  src: url("/fonts/MuseoSansCyrl/MuseoSansCyrl-semibold.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans";
  src: url("/fonts/MuseoSansCyrl/MuseoSansCyrl-light.otf");
  font-weight: 300;
  font-style: normal;
}


// # Perfograma
@font-face {
  font-family: "Perfograma";
  src: url("/fonts/Perfograma/Perfograma.otf");
  font-weight: 400;
  font-style: normal;
}