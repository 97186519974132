body, html,
article, aside, details, figcaption, figure, footer,
header, hgroup, menu, nav, section, main, mark, output,
div, p, br, img, a, span,
h1, h2, h3, h4, h5, h6,
ul, li, ol,
input, button, textarea, select, option, label,
table, tbody, thead, th, tr, td {
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
  text-align: left;
  -webkit-tap-highlight-color: transparent;
  font-style: normal;
  vertical-align: baseline;
  outline: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

table {
  border-spacing: 0 0;
}

pre {
  width: 100%;
  white-space: pre-wrap;
}

article, aside, details, figcaption, figure, footer,
header, hgroup, menu, nav, section, main, mark, output {
  display: block;
}

:focus {
  outline: none;
}

img {
  border: none;
}

iframe {
  overflow: hidden;
  border: 0;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea, input {
  border-radius: 0;
  -webkit-appearance: none;
}

label[for] {
  cursor: pointer;
}

ul {
  -webkit-padding-start: 0 !important;
}

ul, ol, li {
  list-style: none;
}

blockquote {
  position: relative;
  margin: 0;
}

a {
  color: inherit;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

@viewport {
  user-zoom: fixed;
}