.popup {
  padding: 15px;
  overflow: auto;
  z-index: 2;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__exit {
    @include full-size;

    background-color: rgba(0,0,0,0.7);
    cursor: pointer;

    position: fixed;
    top: 0;
    left: 0;
  }

  &__window {
    background-color: $white;
    border-radius: 5px;
    padding: 50px;
    margin: 5% auto;

    max-width: 580px;
  }

  &__cross {
    cursor: pointer;

    position: absolute;
    top: 10px;
    right: 10px;

    height: 30px;
    width: 30px;

    z-index: 1;

    &:hover {
      &:before,
      &:after {
        background-color: $black;
      }
    }

    &:before,
    &:after {
      background-color: #999999;
      content: '';
      transition: background-color 0.2s ease;

      position: absolute;
      top: 5px;
      left: 14px;

      height: 20px;
      width: 1px;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 25px;
    text-align: center;
  }

  &__input {
    border: 1px solid $black;
    border-radius: 6px;
    font-size: 22px;
    font-weight: 300;
    font-family: $font-museo-sans;
    padding: 14px 20px;
    
    height: 54px;
    width: 100%;

    &-container {
      display: block;
      margin-bottom: 16px;
    }

    &-title {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 10px;

      display: block;
    }

    &-error {
      color: red;
      font-size: 14px;
      font-weight: 300;
      margin-top: 4px;

      display: block;
    }
  }

  &__checkboxes {
    margin-top: 30px;
  }

  &__checkbox {
    cursor: pointer;
    margin-bottom: 15px;

    display: block;

    & input {
      display: none;
    }

    & input:checked + &-title:after {
      opacity: 1;
    }

    &-title {
      font-size: 16px;
      font-weight: 300;
      padding-left: 40px;
      line-height: 24px;

      display: block;

      &:before,
      &:after {
        content: '';
        border-radius: 50%;

        position: absolute;
      }

      &:before {
        border: 1px solid #484848;

        top: 0;
        left: 0;

        height: 21px;
        width: 21px;
      }

      &:after {
        background-color: $green;
        opacity: 0;
        transition: opacity 0.2s ease;

        top: 6px;
        left: 6px;

        height: 11px;
        width: 11px;
      }
    }
  }

  &__button {
    background-color: #9ce62e;
    border: 1px solid #93cc3e;
    border-radius: 6px;
    color: $black;
    cursor: pointer;
    font-size: 20px;
    font-weight: 300;
    line-height: 60px;
    margin: 45px auto 0;
    padding: 0 42px;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 0.2s ease;

    height: 62px;
    max-width: 215px;

    &:hover {
      background-color: #93cc3e;
    }
  }

  &__error,
  &__success {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4em;
    text-align: center;
  }

  &__error {
    color: red;
    margin-top: 10px;
  }

  &__success {
    color: #0cb311;
    margin-top: 15px;
  }
}