.terminal {
  @include inline-block;

  background-color: #212121;
  margin: 0 auto;

  height: 362px;
  width: auto;

  &:after {
    content: '';
    background: linear-gradient(to bottom, rgba(0,0,0,0.3), transparent);

    position: absolute;
    top: 100%;
    left: 0;

    height: 40px;
    width: 100%;
  }

  &__top-bar,
  &__bottom-bar {
    background-color: #93cc3e;
    background-position: left;
    background-repeat: repeat;
    background-size: 83px 100%;

    width: 100%;
  }

  &__top-bar {
    background-image: url('/images/terminal/top-bar.svg');
    margin-bottom: 2px;
    height: 19px;
  }

  &__bottom-bar {
    background-image: url('/images/terminal/bottom-bar.svg');
    height: 28px;
  }

  &__ticker {
    color: $white;
    font-family: $font-perfograma;
    overflow: hidden;

    height: 31px;
    width: 100%;

    &:after {
      animation: runlineScreenShine 8s infinite;
      // background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 35%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
      background: linear-gradient(to right, rgba(79, 144, 228, 0) 0%,rgba(43, 142, 255, 0.691) 35%,rgba(7, 141, 251, 0) 99%,rgba(107, 191, 255, 0) 100%);
      content:'';
      transform:translateX(100%);
      z-index:1;

      position: absolute;
      top:0;

      height: 200%;
      width: 300%;
    }

    &-holder,
    &-string {
      padding-left: 100%;
      white-space: nowrap;

      position: absolute;
      left: 0;
    }

    &-holder {
      pointer-events: none;
      opacity: 0;
    }

    &-string {
      color: $white;
      animation-name: runlineAnimation;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transform: translateX(0);

      padding-left: 100%;

      top: 9px;

      display: block;
      width: auto;
      z-index: 1;
    }
  }

  &__stacks {
    height: calc(100% - 80px);
  }

  &__stack {
    @include inline-block;
    @include magic-margin-reset;

    background-color: #212121;
    padding: 3px;

    height: 100%;
    width: 83px;

    &:before {
      @include full-size;

      background-color: rgba(0,0,0,0.5);
      content: '';
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease;

      position: absolute;
      top: 0;
      left: 0;

      z-index: 1;
    }

    &:hover {
      & .terminal {
        &__stack {
          &-buttons {
            opacity: 1;
          }
        }
      }
    }

    &-marker {
      color: #ffffff;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0.13em;
      margin: 10px;
      text-transform: uppercase;
      transform: rotate(-90deg) translateX(-100%);
      transform-origin: top left;
      white-space: nowrap;

      position: absolute;
      top: 0;
      left: 0;
    }

    &-buttons {
      @include full-size;

      background-color: rgba(210, 255, 83, 0.2);
      opacity: 0;
      transition: opacity 0.1s ease;
      z-index: 1;

      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &-button {
      background-color: rgba(0,0,0,0.4);
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.2s ease;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      width: 45px;

      &:hover {
        background-color: rgba(0,0,0,0.7);
      }

      & svg {
        height: 24px;
        width: auto;
      }

      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }

  &__cell {
    @include inline-block;

    background: #333333; // default bg

    width: 100%;
  }

  &__controls {
    &-middle,
    &-bottom {
      margin-top: 2px;

      & span {
        @include inline-block;

        background-color: #1d1d1d;
        position: absolute;
      }
    }

    &-top {
      padding: 3px;
      height: 67px;

      & img {
        @include full-size;

        object-fit: contain;
      }
    }

    &-middle {
      height: 113px;

      & span {
        &:nth-of-type(1) {
          left: 14px;
          bottom: 9px;

          height: 9px;
          width: 11px;
        }

        &:nth-of-type(2) {
          bottom: 18px;
          right: 16px;

          height: 3px;
          width: 4px;
        }

        &:nth-of-type(3) {
          bottom: 9px;
          right: 16px;

          height: 5px;
          width: 11px;
        }
      }
    }

    &-bottom {
      height: 92px;

      & span {
        &:nth-of-type(1) {
          top: 5px;
          left: 14px;

          height: 3px;
          width: 6px;
        }

        &:nth-of-type(2) {
          top: 5px;
          right: 16px;

          height: 3px;
          width: 11px;
        }

        &:nth-of-type(3) {
          top: 12px;
          right: 16px;

          height: 5px;
          width: 11px;
        }
      }
    }

    &-screen {
      background-image: url('/images/terminal/screen.jpg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      top: 11px;
      left: 10px;

      height: 73px;
      width: 57px;
    }
  }
}



// # Modifiers
.terminal {
  &--black {
    & .terminal__cell {
      background: linear-gradient(210deg, #4e4e4f, #414141);
    }
  }

  &--silver {
    & .terminal__cell {
      background: linear-gradient(210deg, #eeeeee, #d8d8d8);
    }
  }

  &--gold {
    & .terminal {
      &__cell {
        background: linear-gradient(165deg, #c0b080, #d4c9a2, #cfc294);
      }

      &__top-bar,
      &__bottom-bar {
        background-color: #d1c095;
      }
    }
  }

  &--wood {
    & .terminal {
      &__cell {
        background-color: #593B26;
        background-image: url('/images/terminal/wood-texture.png');
        background-size: 100% auto;
        background-position: center center;
        background-repeat: no-repeat;
      }

      &__top-bar,
      &__bottom-bar {
        background-color: #cfac7a;
      }
    }
  }

  &__stack {
    &--post-8 {
      & .terminal__cell {
        &:not(:first-child) {
          margin-top: 2px;
        }

        &:nth-child(1) {
          height: 46px;
        }

        &:nth-child(2),
        &:nth-child(6) {
          height: 27px;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          height: 17px;
        }

        &:nth-child(7) {
          height: 40px;
        }

        &:nth-child(8) {
          height: 71px;
        }
      }
    }

    &--post-9 {
      & .terminal__cell {
        &:not(:first-child) {
          margin-top: 2px;
        }

        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(7) {
          height: 27px;
        }

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          height: 17px;
        }

        &:nth-child(8) {
          height: 40px;
        }

        &:nth-child(9) {
          height: 71px;
        }
      }
    }

    &--post-10 {
      & .terminal__cell {
        &:not(:first-child) {
          margin-top: 2px;
        }

        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(8) {
          height: 27px;
        }

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          height: 17px;
        }

        &:nth-child(6),
        &:nth-child(7) {
          width: calc(50% - 1px);
        }

        &:nth-child(6) {
          margin-right: 2px;
        }

        &:nth-child(9) {
          height: 40px;
        }

        &:nth-child(10) {
          height: 71px;
        }
      }
    }

    &--post-24 {
      & .terminal__cell {
        &:not(:first-child) {
          margin-top: 2px;
        }

        &:nth-child(1),
        &:nth-child(24) {
          height: 40px;
        }

        &:nth-child(2) {
          height: 21px;
        }

        &:nth-child(n+3):nth-child(-n+22) {
          height: 12px;
          width: calc(50% - 1px);
        }

        &:nth-child(2n+3):nth-child(-n+22) {
          margin-right: 2px;
        }

        &:nth-child(23) {
          height: 29px;
        }
      }
    }

    &--burger {
      & .terminal__cell {
        &:after {
          background: linear-gradient(45deg, #555555, #7b7b7b);
          content: '';
          opacity: 0.6;

          position: absolute;
          top: 8px;
          left: 8px;

          width: 61px;
        }

        &:not(:first-child) {
          margin-top: 2px;
        }

        &:nth-child(1),
        &:nth-child(6) {
          height: 68px;

          &:after {
            height: 52px;
          }
        }

        &:nth-child(2),
        &:nth-child(5) {
          height: 48px;

          &:after {
            height: 32px;
          }
        }

        &:nth-child(3),
        &:nth-child(4) {
          height: 17px;

          &:after {
            top: 4px;
            height: 9px;
          }
        }
      }
    }

    &--fresh {
      & .terminal__cell {
        &:not(:first-child) {
          margin-top: 2px;
        }

        &:nth-child(1) {
          height: 69px;
        }

        &:nth-child(n + 2) {
          height: 67px;
        }
      }
    }

    &--laundry {
      & .terminal__cell {
        height: 137px;
        width: calc(50% - 1px);

        &:nth-child(odd) {
          margin-right: 2px;
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin-top: 2px;
        }
      }
    }

    &--vending-rfid-soft,
    &--vending-rfid-cold {
      & .terminal__cell {
        &:before,
        &:after {
          content: '';
          opacity: 0.4;
          width: 61px;
        }

        &:after {
          background: linear-gradient(45deg, #3e3e3e, #a59f9f);
        }

        &:not(:first-child) {
          margin-top: 2px;
        }

        &:nth-child(1),
        &:nth-child(2) {
          height: 89px;

          &:before,
          &:after {
            position: absolute;
            top: 8px;
            left: 8px;
          }

          &:before {
            height: 23px;

            background-color: #808080;
            box-shadow: 0 25px 0 #808080, 0 50px 0 #808080;
          }

          &:after {
            height: 73px;
          }
        }

        &:nth-child(3) {
          height: 94px;

          &:before,
          &:after {
            position: absolute;
            top: 8px;
            left: 8px;
          }

          &:before {
            height: 38px;

            background-color: #808080;
            box-shadow: 0 40px 0 #808080;
          }

          &:after {
            height: 77px;
          }
        }
      }
    }
  }
}



// # States
.terminal {
  &__stack {
    &.is-unhighlited {
      &:before {
        opacity: 1;
      }
    }
  }
}