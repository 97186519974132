.settings {
  background: linear-gradient(to bottom, rgba(43, 44, 45, 0.7), rgba(14, 14, 14, 0.7));
  margin-top: 32px;
  padding: 35px 70px 0;

  height: 300px;
  width: 100%;

  &__content {
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1300px;
  }

  &__title {
    color: $white;
    font-family: $font-museo-sans;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 1.3em;
  }

  &__options {
    margin-top: 35px;
  }

  &__option {
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 15px;
    }

    &-circle,
    &-title {
      @include inline-block;
    }

    &-circle {
      border: 2px solid $white;
      border-radius: 50%;

      height: 21px;
      width: 21px;

      &:before {
        background-color: transparent;
        border-radius: 50%;
        content: '';

        position: absolute;
        top: 3px;
        left: 3px;

        height: 11px;
        width: 11px;
      }
    }

    &-title {
      color: $white;
      font-family: $font-museo-sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      padding-left: 16px;

      width: calc(100% - 21px);
    }
  }

  &__stacks {
    margin-top: 27px;
  }

  &__stack {
    @include inline-block;

    cursor: pointer;
    margin-bottom: 20px;
    width: 20%;

    &-head {
      margin: 0 0 6px 30px;

      height: 60px;
      width: 50px;
    }

    &-tooltip {
      position: absolute;
      top: -13px;
      left: -14px;

      height: 11px;
      width: 11px;

      z-index: 1;

      & path {
        transition: fill 0.2s ease;
      }

      &:hover {
        z-index: 2;

        & path {
          fill: $white;
        }
      }

      &:hover &-text {
        pointer-events: visible;
        opacity: 1;
      }

      &-text {
        background-color: rgba(255,255,255,0.9);
        border-radius: 5px;
        color: $black;
        font-family: $font-museo-sans;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 1.4em;
        opacity: 0;
        padding: 10px;
        transition: opacity 0.2s ease;
        pointer-events: none;

        position: absolute;
        top: 23px;
        left: -7px;

        min-width: 98px;

        &:before {
          border: 5px solid transparent;
          border-bottom: 6px solid rgba(255,255,255,0.9);
          content: '';

          position: absolute;
          top: -11px;
          left: 8px;
        }
      }
    }

    &-icon,
    &-counter {
      @include inline-block;
    }

    &-icon {
      margin-right: 5px;

      height: 59px;
      width: 28px;

      & path {
        transition: fill 0.2s ease;
      }

      &:hover {
        & path {
          fill: #c4c4c4;
        }
      }
    }

    &-counter {
      @include magic-margin-reset;

      margin-top: 2px;
      width: 15px;
    }

    &-count,
    &-minus,
    &-plus {
      @include inline-block;

      font-family: $font-museo-sans;
      
      font-weight: 500;
      text-align: center;

      width: 100%;
    }

    &-minus,
    &-plus {
      background-color: #8c8c8b;
      border-radius: 4px;
      color: $black;
      cursor: pointer;
      font-size: 10px;
      line-height: 15px;
      transition: background-color 0.2s ease, opacity 0.2s ease;

      height: 15px;
      width: 15px;

      &:hover {
        background-color: $white;
      }
    }

    &-count {
      color: $white;
      font-size: 12px;
      line-height: 24px;
    }

    &-title {
      color: $white;
      font-family: $font-museo-sans;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.2em;
      text-align: center;
    }
  }

  &__themes {
    margin-top: 30px;

    &-title,
    &-colors,
    &-color {
      @include inline-block;
    }

    &-title {
      color: $white;
      font-family: $font-museo-sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 32px;
      width: 55px;
    }

    &-colors {
      width: calc(100% - 55px);
    }

    &-color {
      border: 1px solid $white;
      border-radius: 50%;
      cursor: pointer;
      margin: 0 auto;
      transition: border-color 0.2s ease;

      height: 32px;
      width: 32px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border-color: #b3b3b3;
      }

      &-column {
        @include inline-block;

        text-align: center;
        width: 25%;
      }

      &-title {
        color: $white;
        font-family: $font-museo-sans;
        font-size: 11px;
        letter-spacing: 0.01em;
        margin-top: 10px;
        text-align: center;
      }
    }
  }

  &__cross {
    cursor: pointer;
    height: 16px;
    width: 16px;

    &:before,
    &:after {
      background-color: $white;
      border-radius: 1px;
      content: '';

      position: absolute;
      top: -2px;
      left: 7px;

      height: 20px;
      width: 2px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__logo {
    margin-top: 30px;
    width: 260px;

    &-label,
    &-title {
      @include inline-block;
    }

    &-label,
    &-title {
      color: $white;
      font-family: $font-museo-sans;
      font-size: 14px;
    }

    &-label {
      cursor: pointer;
      border: 1px solid $white;
      border-radius: 6px;
      font-weight: 700;
      padding: 8px 16px;
      margin-right: 20px;
      transition: border-color 0.2s ease, color 0.2s ease;

      &:hover {
        border-color: #c4c4c4;
        color: #c4c4c4;
      }
    }

    &-title {
      font-weight: 300;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      width: 75px;
    }

    &-cross {
      position: absolute;
      top: 7px;
      right: 0;
    }
  }

  &__runline {
    margin-top: 25px;
    width: 260px;

    &-input {
      background-color: transparent;
      border-bottom: 1px solid $white;
      color: $white;
      font-family: $font-museo-sans;
      font-size: 14px;
      padding-bottom: 3px;

      width: 210px;

      @include placeholder {
        color: #9a9a99;
        font-family: $font-museo-sans;
      }
    }

    &-cross {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}


// # Modifiers
.settings {
  &__column {
    &--location {
      width: 13%;
    }

    &--complectation {
      width: 13%;
    }

    &--stacks {
      width: 35%;
    }

    &--theme {
      width: 22%;
    }
  }

  &__themes {
    &-color {
      &--black {
        background: linear-gradient(45deg, #4E4E4F, #404040);
      }

      &--gold {
        background: linear-gradient(125deg, #C5A32D, #FFEB85);
      }

      &--silver {
        background-color: #d8d8d8;
      }

      &--wood {
        background-color: #593B26;
        background-image: url('/images/terminal/wood-texture.png');
        background-position: -9px -313px;
      }
    }
  }
}


// # States
.settings {
  &__option {
    &.is-active {
      & .settings {
        &__option {
          &-circle {
            &:before {
              background-color: $green;
            }
          }
        }
      }
    }
  }

  &__themes {
    &-color {
      &.is-active {
        border-color: $green;
      }
    }
  }

  &__stack {
    &-icon {
      &.is-active {
        & path {
          fill: #93cc3e;
        }
      }
    }

    &-plus,
    &-minus {
      &.is-disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }

  &__cross {
    &.is-disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}