.constructor {
  width: 100%;

  &__inner {
    background-color: #777777;
    background-size: 100% 893px;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;

    height: 893px;
    width: 100%;

    &:before {
      background: linear-gradient(to top, transparent, rgba(0,0,0,0.5));
      content: '';
  
      position: absolute;
      top: 0;
      left: 0;
  
      height: 250px;
      width: 100%;
    }
  }

  &__title,
  &__subtitle {
    color: $white;
    font-family: $font-museo-sans;
    font-weight: 500;
    text-align: center;
  }

  &__title {
    font-size: 48px;
    margin-top: 45px;
  }

  &__subtitle {
    font-size: 18px;
    letter-spacing: 0.02em;
    margin: 20px 0 55px;
  }

  &__action {
    background-color: #2b2b2b;
    padding: 40px 70px;
    text-align: center;

    &-title {
      color: $white;
      font-size: 26px;
      font-weight: 300;
      margin-bottom: 35px;
      text-align: center;
    }

    &-button {
      background-color: #9ce72e;
      border-radius: 6px;
      cursor: pointer;
      font-size: 22px;
      font-weight: 300;
      padding: 0 42px;
      line-height: 62px;
      text-transform: uppercase;
      transition: background-color 0.2s ease;

      display: inline-block;
      height: 62px;

      &:hover {
        background-color: #93cc3e;
      }
    }
  }
}